import React, { useState } from "react";
import "./group.css";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import Popup from "../../components/Popup/Popup";

const groups = [
  {
    id: 1,
    title: "Music Producers",
    description:
      "A group for music producers to collaborate, share tips, and network.",
    image: "assets/post/22.jpg",
  },
  {
    id: 2,
    title: "Rappers & Lyricists",
    description:
      "Join this group if you are passionate about rapping and writing lyrics.",
    image: "assets/post/33.jpg",
  },
  {
    id: 3,
    title: "Music Directors",
    description:
      "A group for music directors to discuss projects, share experiences, and find new collaborations.",
    image: "assets/post/11.jpg",
  },
  {
    id: 4,
    title: "DJ & Producers Network",
    description:
      "Join this group if you are a DJ or music producer looking to collaborate with other artists.",
    image: "assets/post/88.jpg",
  },
  {
    id: 5,
    title: "Indie Musicians",
    description:
      "A group for indie musicians to share their latest work, events, and more.",
    image: "assets/post/100.jpg",
  },
  {
    id: 6,
    title: "Classical Musicians",
    description:
      "Join this group if you are passionate about classical music, performances, and history.",
    image: "assets/post/55.jpg",
  },
  {
    id: 7,
    title: "Music Lovers",
    description:
      "A group for anyone who loves music, share your favorite tracks and discover new artists.",
    image: "assets/post/77.jpg",
  },
];

export default function GroupPage() {
  const [isPopupOpen, setPopupOpen] = useState(false);

  // Handle "Join" button click
  const handleJoinClick = () => {
    setPopupOpen(true); // Open popup to notify about the upgrade
  };

  // Handle closing the popup
  const handleClosePopup = () => {
    setPopupOpen(false); // Close popup
  };
  return (
    <>
      <Topbar />

      <div className="groupPageContainer">
        <div className="sidebarContainer">
          <Sidebar />
        </div>
        <div className="groupContentContainer">
          <h1 className="pageTitle">Join a Group</h1>
          <div className="groupBoxes">
            {groups.map((group) => (
              <div key={group.id} className="groupBox">
                <img
                  className="groupImage"
                  src={group.image}
                  alt={group.title}
                />
                <h3 className="groupTitle">{group.title}</h3>
                <p className="groupDescription">{group.description}</p>
                <button onClick={handleJoinClick} className="joinButton">
                  Join
                </button>
                {/* Show the popup if isPopupOpen is true */}
                {isPopupOpen && <Popup onClose={handleClosePopup} />}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
