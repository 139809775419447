// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';  // Import Firestore


const firebaseConfig = {
  apiKey: "AIzaSyD1X2OKZfPufDTGv2dyRZxROd-BYBribrE",
  authDomain: "music-comm.firebaseapp.com",
  projectId: "music-comm",
  storageBucket: "music-comm.appspot.com",
  messagingSenderId: "860192802777",
  appId: "1:860192802777:web:313f99bcdb59415f603bc8",
  measurementId: "G-06GBT3E7G1",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(firebaseApp); // Initialize Firestore instance

// Initialize Auth and Storage
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export default firebaseApp;
