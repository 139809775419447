import { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../firebase";  
import { onAuthStateChanged } from "firebase/auth";  
const AuthContext = createContext();  

export const useAuth = () => {
  return useContext(AuthContext);  
};

export const AuthProvider = ({ children }) => {  
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();  
  }, []);

  const logout = () => {
    return auth.signOut();
  };

  return (
    <AuthContext.Provider value={{ currentUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
