import React, { useState } from 'react';
import { auth, storage } from '../../firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import './register.css';

export default function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [error, setError] = useState('');
  
  const navigate = useNavigate(); // Use navigate hook for redirection

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
    }
  };

  const handleRegister = async () => {
    if (password !== passwordAgain) {
      setError('Passwords do not match');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (profileImage) {
        const storageRef = ref(storage, `profilePictures/${user.uid}.jpg`);
        await uploadBytes(storageRef, profileImage);
        const photoURL = await getDownloadURL(storageRef);
        await updateProfile(user, { displayName: username, photoURL });
      } else {
        await updateProfile(user, { displayName: username });
      }

      setError('');
      navigate(`/profile/${username}`); // Redirect to profile page with username
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('The email address is already in use. Please try logging in.');
      } else {
        setError(error.message);
      }
    }
  };

  return (
    <div className="login">
      <div className="loginWrapper">
        <div className="loginLeft">
          <h3 className="loginLogo">Music-comm</h3>
          <span className="loginDesc">Connect with friends and the world around you on Lamasocial.</span>
        </div>
        <div className="loginRight">
          <div className="loginBox">
            <input
              placeholder="Username"
              className="loginInput"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
            <input
              placeholder="Email"
              className="loginInput"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <input
              type="password"
              placeholder="Password"
              className="loginInput"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <input
              type="password"
              placeholder="Password Again"
              className="loginInput"
              onChange={(e) => setPasswordAgain(e.target.value)}
              value={passwordAgain}
            />
            <input type="file" onChange={handleFileChange} className="loginInput" />
            <button className="loginButton" onClick={handleRegister}>Sign Up</button>
            {error && <span className="error">{error}</span>}
            <button className="loginRegisterButton">Log into Account</button>
          </div>
        </div>
      </div>
    </div>
  );
}
