import React from 'react';
import { Link } from 'react-router-dom'; // If you want to link to the upgrade page
import './Popup.css';

const Popup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h2>You need to upgrade your package</h2>
        <p>To join this group, please upgrade your membership package.</p>
        <div className="popup-actions">
          <button onClick={onClose} className="popup-btn cancel-btn">Close</button>
          <Link to="/package" className="popup-btn upgrade-btn">Upgrade</Link>
        </div>
      </div>
    </div>
  );
};

export default Popup;
