import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Profile from "./pages/profile/Profile";
import Register from "./pages/register/Register";
import GroupPage from "./pages/group/Group";
import PackagePage from "./pages/Package/PackagePage";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile/:username" element={<Profile />} />{" "}
          <Route path="/register" element={<Register />} />
          <Route path="/group" element={<GroupPage />} />
          <Route path="/package" element={<PackagePage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
