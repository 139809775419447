import React from "react";
import "./PackagePage.css";
import Topbar from "../../components/topbar/Topbar";

const PackagePage = () => {
  return (
    <>
    <Topbar />
      <div className="packagePage">
        <h1 className="packagePageTitle">Choose Your Package</h1>
        <div className="packageCards">
          <div className="packageCard free">
            <h2>Free</h2>
            <p className="price">Free</p>
            <ul>
              <li>Login / Sign Up</li>
              <li>No Additional Features</li>
            </ul>
            <button className="btn">Choose Free</button>
          </div>
          <div className="packageCard advanced">
            <h2>Advanced</h2>
            <p className="price">$10/month</p>
            <ul>
              <li>Login / Sign Up</li>
              <li>Join Groups</li>
            </ul>
            <button className="btn">Choose Advanced</button>
          </div>
          <div className="packageCard pro">
            <h2>Pro</h2>
            <p className="price">$25/month</p>
            <ul>
              <li>Login / Sign Up</li>
              <li>Join Groups</li>
              <li>Follow Other People</li>
            </ul>
            <button className="btn">Choose Pro</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagePage;
